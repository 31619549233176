import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TeacherModel } from "@/models";
import { TeCommon } from "../Common";

export class InfoForm {
    acc = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) phone = '';
    school = '';
    pic = ''
    tid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherEditController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherEditController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private infoForm = new InfoForm();
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        name: '',
        phone: '',
        pic: '',
    };

    public async created() {
        const item: { [key: string]: string } = {
            tid: this.tid,
            token: this.token,
        }
        const editData = await TeacherModel.teGet(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            tid: string;
            acc: string;
            name: string;
            phone: string;
            pic: string;
            school: string;
        };
        this.infoForm.tid = item.tid;
        this.infoForm.acc = item.acc;
        this.infoForm.name = item.name;
        this.infoForm.phone = item.phone;
        this.infoForm.pic = item.pic;
        this.infoForm.school = item.school;
    }

    private changefile(event: any) {
        const file = event.target.files[0];

        if (file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'jpeg') {
                this.lightbox.errorMsg = '上傳檔案只能是 jpg、png、jpeg 格式！';
                this.lightbox.showLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.lightbox.errorMsg = '上傳檔案不能超過 3MB！';
                this.lightbox.showLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.infoForm.pic = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    private validateAndSubmit() {
        validate(this.infoForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        name: '',
                        phone: '',
                        pic: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const formobj: HTMLFormElement = document.getElementById('infoForm') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('tid', this.infoForm.tid);
                    data.append('token', this.token);

                    const errNo = await TeacherModel.teEdit(data);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            TeCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/teacher/teacher");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }

                }
            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}